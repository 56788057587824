<template>
  <div class="row">
    <div class="col-md-12">
      <div id="filter-card" class="row py-3 px-4">
        <div class="col-lg-12 col-xl-12 px-0">
          <form @submit.prevent="filterResult()">
            <div class="row align-items-end py-0 my-0 mx-0" v-if="filters"> 
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :model.sync="team_ids"
                  :not-list="false"
                  :options="teamOptions"
                  :title="$t('scope.Team') + ':'"
                  :required="false"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  input-width="200px"
                  :isOpenMultiselect="true"
                ></custom-multi-select>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :model.sync="customer_ids"
                  :not-list="false"
                  :options="customerOptions"
                  :title="$t('order.customer') + ':'"
                  :required="false"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  input-width="200px"
                  name="order_customer_id"
                ></custom-multi-select>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :model.sync="represantative_ids"
                  :not-list="false"
                  :options="represantativeOptions"
                  :title="$t('Müşteri Temsilcisi') + ':'"
                  :required="false"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  input-width="200px"
                  name="represantative_id"
                ></custom-multi-select>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :model.sync="order_ids"
                  :not-list="false"
                  :options="orderOptions"
                  :title="$t('report.order_no') + ':'"
                  :required="false"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  input-width="200px"
                  name="order_ids"
                ></custom-multi-select>
              </div> 
            </div>
            <div class="row align-items-end py-0 my-0 mx-0">
              <div class="col-lg-4 col-md-6 my-lg-2 my-sm-0 row mx-0 px-0">
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                    :helper-text="$t('general.start_date')"
                    :model.sync="filters.start_date"
                    name="start_time"
                    title="general.start_date" 
                  >
                  </date-filter>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                    :helper-text="$t('general.end_date')"
                    :model.sync="filters.end_date"
                    name="end_date"
                    title="general.end_date" 
                  >
                  </date-filter>
                </div>                
              </div>
              <div
                class="col-xl-1 col-lg-1 col-md-1 col-sm-12 mt-3 px-1 d-flex align-items-center justify-content-end m-0 pr-0 row"
                style="margin: 0 !important"
              >
                <div class="col-12 pr-0">
                  <button
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1"
                    @click.prevent="filterResult()"
                  >
                    {{ $t("general.filter") }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1"
                    @click="resetFilters"
                  >
                    {{ $t("general.clear") }}
                  </button>
                </div>
              </div>  
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <!-- <button @click="changeChart" class="btn btn-success btn-sm mb-3">      
        {{ !change_chart ?  $t("Bar Grafiği") : $t("Geri") }}
      </button> -->
       <dashboard-box :title="$t('report.endorsemet_report')">
        <template v-slot:preview>
          <div class="row" v-if="!change_chart">
            <div class="col-md-6" style="margin-top:50px">
              <div class="graphic-container">
                <apexchart
                  type="pie"
                  height="350"
                  :options="chartOptionsPieChart"
                  :series="pieChartSeries"
                  width="350px"
                  style="padding: 20px" 
                ></apexchart>
              </div>
            </div>
            <div class="col-md-6" style="margin-top:50px">
              <div class="graphic-container"> 
                <div class="option-bar-title black hover" ref="childButton" @click="parentTableChange('all')">
                  <span>Toplam ESC Sayısı (Siparişe Bağlanan) :</span>
                  <span>{{
                    totalOrderAmounts.total_amount ? totalOrderAmounts.total_amount : 0 | formatNumber
                  }}</span>
                </div>
                <div class="option-bar-title green hover" @click="parentTableChange('success')">
                  <span>Maliyetinde Kalanlar :</span>
                  <span>{{
                    totalOrderAmounts.success_amount ? totalOrderAmounts.success_amount  : 0 | formatNumber
                  }}</span>
                </div>
                <div class="option-bar-title red hover" @click="parentTableChange('fail')">
                  <span class="float-start">Maliyeti Değişenler :</span>
                  <span class="float-end">{{
                    totalOrderAmounts.fail_amount ? totalOrderAmounts.fail_amount: 0 | formatNumber
                  }}</span>
                </div>
              </div>
            </div>  
          </div>
          <div class="row" v-if="change_chart">
            <div class="col-md-6" style="margin-top:50px">
              <div >
                <div 
                  v-on:click="resizeBool ? null : resize()"
                  :class="{ 'graphic-container': resizeBool }"
                  class="mt-0 pt-0"
                >
                  <apexchart
                    type="bar"
                    :height="height"
                    :options="chartTeamBarOptions"
                    :series="seriesTeam"
                    :width="tmpWidth"
                  ></apexchart>
                </div>
              </div>

            </div>
            <div class="col-md-6" style="margin-top:50px">
            <div >
              <div   
                v-on:click="resizeBool ? null : resize()"
                :class="{ 'graphic-container': resizeBool }"
                class="mt-0 pt-0"
              >
                <apexchart
                  type="bar"
                  :height="height"
                  :options="chartCompanyBarOptions"
                  :series="seriesCompany"
                  :width="tmpWidth"
                ></apexchart>
              </div>
            </div>

            </div>
          </div>
        </template>
      </dashboard-box> 
    </div>

    <div class="col-md-12">
      <dashboard-box>
        <template v-slot:preview>
          <div @contextmenu.prevent="onContextMenu">
            <data-table
              :bordered="false"
              :borderless="true"
              :fields.sync="fields"
              :hover="false"
              :items="tempChange"
              :no-border-collapse="true"
              :outlined="false"
              :striped="true"
              :disabled-infinite-loading="false"
              :infiniteId="infiniteId"
              :sticky-header="true"
              tableVariant="''"
              :page.sync="page"
              :perPage.sync="perPage"
              @hitBottom="onHitBottom"
              :sortBy.sync="order"
              :sortDesc.sync="sort"
              :responsive="true"
              @onClickView="onClickView"
            ></data-table>
          </div>
        </template>
      </dashboard-box>
    </div>

    <b-modal id="select-detail-modal" ref="select-detail-modal" hide-header hide-header-close
    scrollable size="xl"> 
      <div class="row">
        <div class="col-6">
          <div style="text-align:center;" class="d-flex justify-content-center items-center cursor-pointer py-2  bg-primary text-white custom-tab-box rounded m-5">
            <h3>Oluşturulan ESC Raporu</h3>
          </div>
          <div style="border:1px solid #414899;border-radius:5px;padding:5px;margin-bottom:10px">
            <table class="table">
              <thead>
                <th>{{ $t('Kumaş') }}</th>
                <th>{{ $t('Sarfiyat(Pastal)') }}</th>
                <th>{{ $t('Fiyat') }}</th>
                <th>{{ $t('Toplam') }}</th>
              </thead>
              <tbody> 
                <tr  v-for="(fabric,index) in this.detailResult.esc_fabric_list" :key="index">
                  <td>{{ fabric.fabric_name }}</td>
                  <td>{{ fabric.pastal }}</td>
                  <td>{{ fabric.price }}</td>
                  <td>{{ numberFormat(Number(fabric.pastal)*Number(fabric.price),4) }}</td>
                  <td></td>
                </tr>
              </tbody>
            </table>   
          </div>
          <div style="border:1px solid #414899;border-radius:5px;padding:5px ">
            <table class="table">
              <thead>
                <th>{{ $t('Masraflar') }}</th>
                <th>{{ $t('Planlanan Tutar') }}</th>
                <th>{{ $t('Miktar') }}</th>
                <th>{{ $t('Birim') }}</th>
              </thead>
              <tbody>
                <tr  v-for="(expense,index) in this.detailResult.esc_expenses" :key="index">
                  <td>{{ expense.name }}</td>
                  <td>{{ expense.cost }}</td>
                  <td>{{ expense.quantity }}</td>
                  <td>{{ expense.unit }}</td>
                  <td></td>
                </tr>
              </tbody>
            </table>   
          </div>
        </div>
        <div class="col-6"> 
          <div style="text-align:center;" class="d-flex justify-content-center items-center cursor-pointer py-2  bg-primary text-white custom-tab-box rounded m-5">
            <h3>Sipariş ESC Raporu</h3>
          </div>
          <div style="border:1px solid #414899;border-radius:5px;padding:5px;margin-bottom:10px">
            <table class="table">
              <thead>
                <th>{{ $t('Kumaş') }}</th>
                <th>{{ $t('Sarfiyat(Pastal)') }}</th>
                <th>{{ $t('Fiyat') }}</th>
                <th>{{ $t('Toplam') }}</th>
              </thead>
              <tbody> 
                <tr  
                    v-for="(fabric,index) in detailResult.order_fabric_list" :key="index"
                    :class="fabric.fabric_compare == 0 ?'red_back' : ''"
                >
                  <td>{{ fabric.fabric_name }}</td>
                  <td>{{ fabric.pastal }}</td>
                  <td>{{ fabric.price }}</td>
                  <td>{{ numberFormat(Number(fabric.pastal)*Number(fabric.price),4) }}</td>
                  <td></td>
                </tr>
              </tbody>
            </table>   
          </div>
          <div style="border:1px solid #414899;border-radius:5px;padding:5px ">
            <table class="table">
              <thead>
                <th>{{ $t('Masraflar') }}</th> 
                <th>{{ $t('Planlanan Tutar') }}</th>
                <th>{{ $t('Gerçekleşen Tutar') }}</th>
                <th>{{ $t('Miktar') }}</th>
                <th>{{ $t('Birim') }}</th>
              </thead>
              <tbody>
                <tr  v-for="(expense,index) in detailResult.order_expenses" :key="index" :class="expense.compare == 0 ?'red_back' : ''">
                  <td>{{ expense.name }}</td>
                  <td>{{ expense.planned }}</td>
                  <td>{{ expense.actual }}</td>
                  <td>{{ expense.cost }}</td> 
                  <td>{{ expense.unit }}</td>
                  <td></td>
                </tr>
              </tbody>
            </table>   
          </div>
        </div>
      </div>            
      <template #modal-footer>
        <div class="col-12 text-center">
          <button class="btn btn-sm btn-outline-danger p-2 btn-pill font-weight-bold cursor-pointer mr-5"
            @click="closeSelectDetailModal">
            {{ $t('general.close') }}
          </button>
        </div>
      </template>
    </b-modal>  
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapMutations } from "vuex";
import moment from "moment";

// COMPONENTS
import DashboardBox from "@/assets/components/DashboardBox";
import DataTable from "@/assets/components/dataTable/DataTable"; 
import InputFilter from "@/assets/components/filters/InputFilter";
import SelectFilter from "@/assets/components/filters/SelectFilter";
import DateFilter from "@/assets/components/filters/DateFilter";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect"; 

import {
  HANDLE_INFINITE_SCROLL,
  LOADING,
  SET_ITEMS,
  SET_LOADING,
  ITEMS,
  SET_FILTER,
  FILTER,  
} from "@/core/services/store/report/escReport.module";

import {
  GET_ITEMS,
  GET_ITEMS as REST_GET_ITEM,
} from "@/core/services/store/REST.module";
import {
  LARAVEL_DATE_FORMAT,
  MOMENT_SYSTEM_DATE_FORMAT,
} from "@/core/config/constant";
import * as _ from "lodash";
import ContextMenu from "@/assets/components/contextMenu/ContextMenu";
import {
  ESC_REPORT_START_DATE_STORAGE_NAME,
  ESC_REPORT_END_DATE_STORAGE_NAME,
} from "@/core/storage/storage-names"; 

export default {
  name: "EscReport",
  components: {
    SelectFilter,
    InputFilter,
    DataTable,
    DashboardBox, 
    DateFilter,
    CustomMultiSelect,
    ContextMenu, 
  },
  computed: {
    ...mapGetters({ 
      loading: LOADING,
      filter: FILTER,
      items: ITEMS,
    }),
    filters: {
      get: function () {
        return this.filter;
      },
      set: function (value) {
        this.setFilter(value);
      },
    }, 
    formattedReport() {
      if (
        !this.items ||
        this.items.data === null ||
        this.items.data === undefined
      )
        return []; 
      let self = this;
      let temp = []; 
      this.items.data.forEach((item) => {
         temp.push({
          id: item.id,
          company_name: item.company_name,
          esc_number: item.esc_number,
          order_number: item.order_number,
          representative_name: item.representative_name,
          order_amount: Number(item.order_amount).toLocaleString(
            "tr-TR",
            {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }
          ),
          esc_date: item.esc_date,
          esc_creator: item.esc_creator,
          user_name: item.user_name,
          total_esc_cost :  item.total_esc_cost,
          total_order_cost :  item.total_order_cost,
          esc_expenses:item.esc_expenses,
          order_expenses:item.order_expenses,
          row_compare:item.row_compare,
          esc_fabric_list:item.esc_fabric_list,
          order_fabric_list:item.order_fabric_list,
          total_order_amounts : item.total_order_amounts
        });
      });


      let remaining_cost =0
      let exceeding_cost =0
      this.totalOrderAmounts.success_amount =0;
      this.totalOrderAmounts.fail_amount =0;
      this.totalOrderAmounts.total_amount =0;
      temp.forEach(element => {
        element.row_compare == 1 ? remaining_cost++ : exceeding_cost++; 
        self.totalOrderAmounts.success_amount += Number (element.total_order_amounts.success_amount)
        self.totalOrderAmounts.fail_amount +=  Number (element.total_order_amounts.fail_amount)
        self.totalOrderAmounts.total_amount +=  Number (element.total_order_amounts.total_amount )
      }); 

      this.pieChartSeries = [ remaining_cost,exceeding_cost ];

      
      let companyRemainigList = [];
      let companyExceedingList = [];
      let companyTotalList = [];
      this.chartBarCompanyCategories=[]
      this.seriesCompany   = [
        {
          name: "Toplam ESC Sayısı(Siparişe Bağlanan)",
          data: companyTotalList,
          color: "#6462A3",
        },
        {
          name: "Maliyetinde Kalanlar",
          data: companyRemainigList,
          color: "#00E396",
        },
        {
          name: "Maliyeti Değişenler",
          data: companyExceedingList,
          color: "#E31818",
        },
      ];

      let teamRemainigList = [];
      let teamExceedingList = [];
      let teamTotalList = [];
      this.chartBarTeamCategories=[]
      this.seriesTeam   = [
        {
          name: "Toplam ESC Sayısı(Siparişe Bağlanan)",
          data: teamTotalList,
          color: "#6462A3",
        },
        {
          name: "Maliyetinde Kalanlar",
          data: teamRemainigList,
          color: "#00E396",
        },
        {
          name: "Maliyeti Değişenler",
          data: teamExceedingList,
          color: "#E31818",
        },
      ];  

      this.tempChange =temp
      return temp;
    },
    chartOptionsPieChart() {
      let tmpChartOptions = {
        chart: {
          width: 380,
          type: "pie",
        },
        colors: ["#1FE757","#FD1515"],
        title: {
          text: "ESC Pasta Grafiği",
          style: {
            fontSize: "13px",
            fontWeight: "bold",
            color: "#5cb7e0",
          },
        },
        labels: ["Maliyetinde Kalan Miktar","Maliyeti Aşan Miktar"],
        legend: {
          show: true,
          position: "bottom",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                show: false,
                position: "bottom",
              },
            },
          },
        ],
      };
      return tmpChartOptions;
    },
    chartTeamBarOptions() {
      let seriesLength = 0.99;
      if (this.resizeBool) {
        this.tmpWidth = window.innerWidth * seriesLength;
      } else {
        let widhMiniScreen = 0;
        if (window.innerWidth <= 1500) {
          widhMiniScreen = 2.8;
        } else {
          widhMiniScreen = 3.1;
        }
        this.tmpWidth = window.innerWidth / widhMiniScreen;
      }
      let self = this;
      let tmpChartOptions = {
        chart: {
          type: 'bar',
          height: 350
        },   
        dataLabels: {
          enabled: false,
          formatter: function (val) {
            return val + "AD";
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#fff"]
          }
        },
        plotOptions: {
          bar: {
            colors: {
              ranges: [{
                from: 1000000000,
                to: 0,
                color: '#00ff00'
              }, {
                from: -1000000000,
                to: 0,
                color: '#ff0000'
              }]
            },
            columnWidth: '80%',
          }
        },
        stroke: {
          show: true, 
          colors: ['transparent']
        },
        xaxis: {
          categories: this.chartBarTeamCategories,
        },
        yaxis: {
          title: {
            text: 'AD'
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) { 
              return val+'AD'
            }
          }
        }
      };
      return tmpChartOptions;
    },
    chartCompanyBarOptions() {
      let seriesLength = 0.99;
      if (this.resizeBool) {
        this.tmpWidth = window.innerWidth * seriesLength;
      } else {
        let widhMiniScreen = 0;
        if (window.innerWidth <= 1500) {
          widhMiniScreen = 2.8;
        } else {
          widhMiniScreen = 3.1;
        }
        this.tmpWidth = window.innerWidth / widhMiniScreen;
      }
      let self = this;
      let tmpChartOptions = {
        chart: {
          type: 'bar',
          height: 350
        },   
        dataLabels: {
          enabled: false,
          formatter: function (val) {
            return val + "AD";
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#fff"]
          }
        },
        plotOptions: {
          bar: {
            colors: {
              ranges: [{
                from: 1000000000,
                to: 0,
                color: '#00ff00'
              }, {
                from: -1000000000,
                to: 0,
                color: '#ff0000'
              }]
            },
            columnWidth: '80%',
          }
        },
        stroke: {
          show: true, 
          colors: ['transparent']
        },
        xaxis: {
          categories: this.chartBarCompanyCategories,
        },
        yaxis: {
          title: {
            text: 'AD'
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) { 
              return val+'AD'
            }
          }
        }
      };
      return tmpChartOptions;
    },
  },
  data() {
    return {
      // Filters & pagination
      infiniteId: 1,
      sort: "desc",
      order: "id",
      height: 350,
      resizeChartValue: null, 
      search: null,
      onClickButton: false,
      paginate: [],
      page: 1,
      perPage: 25,
      startDate: moment().subtract(30, "d"),
      endDate: moment().add(1, "d"),
      reportListUrl: "api/report/esc-report", 
      tempFilters: {}, 
      fields: [
        {
          key: "company_name",
          label: this.$t("Müşteri"),
          sortable: true,
          class:
            "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg ",
        },
        {
          key: "esc_number",
          label: this.$t("ESC No"),
          sortable: true,
          class:
            "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg ",
        },
        {
          key: "order_number",
          label: this.$t("report.order_number"),
          sortable: true,
          class:
            "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg ",
        }, 
        {
          key: "representative_name",
          label: this.$t("Müşteri Temsilcisi"),
          sortable: true,
          class:
            "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg ",
        },
        {
          key: "order_amount",
          label: this.$t("Sipariş Adeti"),
          sortable: true,
          class:
            "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg ",
        },
        {
          key: "esc_date",
          label: this.$t("ESC Tarihi"),
          sortable: true,
          class:
            "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg ",
        },
        {
          key: "esc_creator",
          label: this.$t("ESC Oluşturan"),
          sortable: true,
          class:
            "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg ",
        },
        {
          key: "total_esc_cost",
          label: this.$t("Planlanan Maliyet(₺)"),
          sortable: true,
          class:
            "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg ",
        },
        {
          key: "total_order_cost",
          label: this.$t("Gerçekleşen Maliyet(₺)"),
          sortable: true,
          class:
            "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg ",
        },
        {
          key: "show_esc_report_detail",
          label: this.$t("Show"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },      
      ],  
      team_ids: [],
      teamOptions: [],
      customerOptions:[], 
      customer_ids: [], 
      represantativeOptions:[], 
      represantative_ids: [], 
      order_ids: [],
      orderOptions: [],
      detailResult:[],
      pieChartSeries:[],
      totalOrderAmounts:{
        success_amount:0,
        fail_amount:0,
        total_amount:0
      },
      tempChange:{},
      change_chart:false,
      chartBarTeamCategories: [],
      chartBarCompanyCategories: [],
      seriesCompany:[],
      seriesTeam:[],
      resizeBool: false,
      
    };
  },
  methods: {
    changeChart(){
      this.change_chart = !this.change_chart
    },
    groupedData (data){ 
      return Object.values(data).reduce((acc, item) => {
          const { user, success_amount, fail_amount, total_amount } = item.total_order_amounts;

          if (!acc[user]) {
              acc[user] = {
                  success_amount: 0,
                  fail_amount: 0,
                  total_amount: 0
              };
          }

          acc[user].success_amount += success_amount;
          acc[user].fail_amount += fail_amount;
          acc[user].total_amount += total_amount;

          return acc;
      }, {});
    },
    numberFormat(value,digit){
      return Number(value).toLocaleString(
          "tr-TR",
          {
            minimumFractionDigits: digit,
            maximumFractionDigits: digit,
          }
        );
    },
    resize() {
      let seriesLength = 0.8;

      this.resizeBool = !this.resizeBool;
      if (this.resizeBool) {
        this.height = 400;
        this.tmpWidth = window.innerWidth * seriesLength;

        this.$emit("resizecard", "linechart");
      } else {
        let widthMiniScreen = 0;
        if (window.innerWidth <= 1500) {
          widthMiniScreen = 2.8;
        } else {
          widthMiniScreen = 3.1;
        }
        this.height = 350;
        this.tmpWidth = window.innerWidth / widthMiniScreen;
        this.$emit("resizecard", null);
      }
    },
    parentTableChange(type){
      this.tempChange = this.formattedReport;
      if(type=='success'){ 
        this.tempChange =  this.tempChange.filter(item => item.row_compare == 1);
      }else if(type=='fail'){
        this.tempChange =  this.tempChange.filter(item => item.row_compare == 0);
      }else{
        this.tempChange = this.formattedReport;
      } 
    },
    onClickView(payload) {
      this.detailResult = payload
      this.openSelectDetailModal()
    },
    openSelectDetailModal() {
      this.$refs['select-detail-modal'].show();
    },
    closeSelectDetailModal() { 
      this.$refs['select-detail-modal'].hide();
    },
    resizeCard(val) {
      this.resizeChartValue = val;
    },
    ...mapMutations({
      setFilter: SET_FILTER,
    }),
    beforeFilterResult() {
      setTimeout(() => {
        this.filterResult();
      }, 100);
    }, 
    filterResult() {
      localStorage.setItem(
        ESC_REPORT_START_DATE_STORAGE_NAME,
        moment(this.filters.start_date)
      );
      localStorage.setItem(
        ESC_REPORT_END_DATE_STORAGE_NAME,
        moment(this.filters.end_date)
      );
      let self = this;
      this.page = 1;
      this.filters.page = 1;
      this.infiniteId++;
      this.onClickButton = true;
      this.onHitBottom();
    },
    onHitBottom($state = null) {
      if (this.loading) {
        return;
      }
      let self = this;
      let filters = _.cloneDeep(this.filters);

      this.tempFilters = filters;
      this.$set(
        filters,
        "start_date",
        moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filters,
        "end_date",
        moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT)
      );

      this.$set(filters, "order", this.order);
      this.$set(filters, "sort", this.sort); 

      if (this.team_ids) {
        this.$set(filters, "team_ids", JSON.stringify(this.team_ids));
      }
      if (this.order_ids) {
        this.$set(filters, "order_ids", JSON.stringify(this.order_ids));
      } 
      if (this.customer_ids) {
        this.$set(filters, "customer_ids", JSON.stringify(this.customer_ids));
      }
      if (this.represantative_ids) {
        this.$set(filters, "represantative_ids", JSON.stringify(this.represantative_ids));
      }
      self.$store
        .dispatch(HANDLE_INFINITE_SCROLL, {
          url: self.reportListUrl,
          filters: filters,
          $state: $state,
        })
        .then((result) => {
          this.onClickButton = false;
          if (result.status) {
            this.parentTableChange();
            this.infiniteId++;
            if ($state) {
              $state.loaded();
            }
          } else {
            if ($state) {
              $state.complete();
            }
          }
        });
    },
    orderFilter(param){      
      let params=''; 
      if(param.team_id){
        params += '&team_id='+JSON.stringify(param.team_id);
      }
      if(param.company_id ){
        params += '&company_id='+JSON.stringify(param.company_id);
      }
        this.$store
          .dispatch(GET_ITEMS, {
            url: "api/report/order-list-packing?"+params, 
          })
          .then((result) => { 
            if (result.status) {
              this.orderOptions = result.data
            } else {
              console.log({ error: result });
            }
          });
    },
    resetFilters() {
      (this.sort = "desc"),
        (this.order = "id"),
        this.setFilter({
          page: 1,
          order_ids: [],
          supplier_ids: [],
          per_page: 25,
          search: "",
          start_date: moment().subtract(30, "d").format(LARAVEL_DATE_FORMAT),
          end_date: moment().add(1, "d").format(LARAVEL_DATE_FORMAT),
        });  
    },
    setup() {
      let self = this,
      promises = [];

      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/team-list",
        })
      );

      promises.push(
        this.$store.dispatch(GET_ITEMS, { 
          url: "api/companies",
        })
      ); 

      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url:  "api/representative-list",
        })
      );

      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/report/order-list-packing",
        })
      );
      
 
      Promise.all(promises).then((results) => {
         
        if (results[0].status) {
          this.teamOptionsData = results[0].data;
          self.teamOptions = self.convertArrayToObjectByKey(
            results[0].data,
            "id",
            "user_name"
          );
        }

        if (results[1].status) {
          self.customerOptions = self.convertArrayToObjectByKey(
            results[1].data,
            "id",
            "name"
          );
        }

        if (results[2].status) {
          self.represantativeOptions = self.convertArrayToObjectByKey(
            results[2].data,
            "id",
            "user_name"
          );
        }

        if (results[3].status) {
          self.orderOptions = results[3].data;
        } 
      });
    },
  },
  created() {
    this.$store.commit(SET_LOADING, false);
    this.$store.commit(SET_ITEMS, {});
  },
  mounted() {
    let self = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("general.dashboard"), route: "dashboard" },
      { title: this.$t("Esc Raporu") },
    ]);

    if (!this.isUserGranted("EscTable", ["viewAny"])) {
      return false;
    }

    if (!this.filter) {
      this.resetFilters();
    }

    this.filters.end_date = localStorage.hasOwnProperty(
      ESC_REPORT_END_DATE_STORAGE_NAME
    )
      ? moment(
          localStorage.getItem(ESC_REPORT_END_DATE_STORAGE_NAME)
        )
      : moment().add(1, "d").format(LARAVEL_DATE_FORMAT);

    this.filters.start_date = localStorage.hasOwnProperty(
      ESC_REPORT_START_DATE_STORAGE_NAME
    )
      ? moment(
          localStorage.getItem(ESC_REPORT_START_DATE_STORAGE_NAME)
        )
      : moment().subtract(30, "d").format(LARAVEL_DATE_FORMAT);

    this.setup();
    this.onClickButton = true;
    setTimeout(this.filterResult, 500);
  },
  watch: {
    sort() {
      this.beforeFilterResult();
    },
    order() {
      this.beforeFilterResult();
    }, 
    "company_id"(value){ 
      this.orderFilter({'company_id':value})      
    },
    "team_id"(value){ 
      this.orderFilter({'team_id':value})      
    },
  },
  filters: {
    formatNumber(value) {
      if (!value || isNaN(value)) {
        return "0";
      }
      return new Intl.NumberFormat("tr-TR").format(value);
    },
  },
};
</script>

<style>
*:focus {
  outline: none;
}

#dashboard-card-body {
  padding-bottom: 5px !important;
}

.info-total-area {
  margin-bottom: 1%;
  margin-right: -1%;
  padding-right: 5%;
}
.info-total-area > div {
  flex: 0 0 17%; /* Kutuların genişliğini ayarlamak için istediğiniz değeri kullanabilirsiniz */
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  font-weight: 600;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.general-area {
  margin-bottom: 1%;
  padding-left: 2%;
}
.general-area > div {
  flex: 1; /* Kutuların genişliğini ayarlamak için istediğiniz değeri kullanabilirsiniz */
  text-align: center;
  font-weight: 600;
  font-size: 13px;
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.total-area-text {
  font-size: 13px;
  font-weight: 400;
}
</style>
<style lang="scss">
.b-table-sticky-header > .table.b-table > thead > tr > th {
  background-color: white !important;
}
.b-table-sticky-header {
  max-height: 500px;
}
.b-table-sticky-header > .table.b-table > tbody > tr > th {
  background-color: white !important;
}
.red_back{
  background-color:red ;
  color:white;
  border-radius:20px
}
.option-bar {
  align-content: center;
}
.option-bar-title { 
  cursor:pointer;
  font-size: 14px;
  font-weight: 800;
  line-height: 1;
  padding: 25px 20px;
  margin: 20px 0px;
  justify-content: space-between;
  line-height: 1.2;
  width: 450px;
}
.option-bar-title span:nth-child(1) {
  width: 100px;
}
.option-bar-title span:nth-child(2) {
  float: right;
}
.black,
.green,
.red,
.yellow {
  border: 1px solid;
  border-radius: 10px;
}
.black {
  border-color: #000000;
  box-shadow: 0 0 10px 0 #000000;
}
.green {
  border-color: #1fe757;
  box-shadow: 0 0 10px 0 #1fe757;
}
.red {
  border-color: #fd1515;
  box-shadow: 0 0 10px 0 #fd1515;
}
.yellow {
  border-color: #e8ed0c;
  box-shadow: 0 0 10px 0 #e8ed0c;
}
.hover:hover{
  font-size: 16px;
  color:orange
}
</style>
